<template>
  <b-card :title="title">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Choose due date"
              label-for="due_date"
            >
              {{ form.due_date | formatDateOnly }}
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Choose time"
              label-for="time"
            >
              {{ form.time ? form.time : 'Not defined' }}
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Package Count"
              label-for="packages_count"
            >
              {{ form.packages_count }}
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Choose the address"
              label-for="address"
            >
              {{ form.address.address }}
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Notes"
              label-for="v-textarea"
            >
              {{ form.notes }}

            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { required } from '@core/utils/validations/validations'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationObserver } from 'vee-validate'
import {
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import axios from '@/libs/axios'

export default {
  name: 'PickupsShowVue',
  components: {
    ValidationObserver,
    BCard,
    BFormGroup,
    BForm,
    BRow,
    BCol,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date(today)
    /* minDate.setMonth(minDate.getMonth() - 2)
    minDate.setDate(15) */
    // 15th in two months
    // const maxDate = new Date(today)
    /* maxDate.setMonth(maxDate.getMonth() + 2)
    maxDate.setDate(15) */
    return {
      title: this.$route.params.id ? 'Show pickup' : 'Add new pickup',
      form: {
        client_id: null,
        due_date: null,
        address: null,
        time: null,
        packages_count: null,
        notes: null,
      },
      required,
      url: this.$route.params.id ? `/pickups/${this.$route.params.id}` : '/pickups/',
      clients: [],
      statuses: [],
      min: minDate,
      create_shipment: false,
      upload_file: false,
      addresses: [],
      // max: maxDate,
    }
  },
  mounted() {
    this.addresses = JSON.parse(localStorage.getItem('userData')).addresses
    if (this.$route.params.id) {
      this.getData()
      this.getStatues()
    }
  },
  methods: {
    validationForm() {
      if (this.$route.params.id) {
        // eslint-disable-next-line no-underscore-dangle
        this.form._method = 'PUT'
      }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.form.client_id = JSON.parse(localStorage.getItem('userData')).id
          axios.post(this.url, this.form).then(resp => {
            if (resp) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Data is submitted successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (this.create_shipment) {
                this.$router.push(`/shipment/create?pickup_id=${resp.data.data.id}`)
              } else if (this.upload_file) {
                this.$router.push(`/shipment/uploadFile?pickup_id=${resp.data.data.id}`)
              } else {
                this.$router.push('/pickup')
              }
            }
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'some error occurred',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    getData() {
      axios.get(`/pickups/${this.$route.params.id}`).then(resp => {
        this.form = resp.data.data
      })
    },
    cancel() {
      this.$router.push('/pickup')
    },
    searchClient(search, loading) {
      if (search) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      axios.post('/clients/search', {
        search: { value: search },
      }).then(res => {
        // eslint-disable-next-line no-param-reassign
        vm.clients = res.data.data
        loading(false)
      })
    }, 350),
    getStatues() {
      axios.get('/pickupStatuses/').then(resp => {
        this.statuses = resp.data.data
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
